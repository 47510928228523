<template>
  <div class="w-full">
    <Component
      :is="`blueprint-${page?.entry?.blueprint?.replaceAll('_', '-')}`"
      :page="page?.entry"
    />
  </div>
</template>

<script setup>
import { useMainStore } from '@/stores/main'
import { storeToRefs } from 'pinia'
const route = useRoute()
const main = useMainStore()
const { seo_defaults } = storeToRefs(main)

const { data: page } = await useAsyncGql({
  operation: 'Entry',
  variables: {
    uri: route.path,
  },
})

if (!page?.value?.entry) {
  throw createError({
    fatal: true,
    statusCode: 404,
  })
}

// META TAGS
await useHead({
  title: getPageTitle(page.value?.entry),
  meta: generateMeta(page.value?.entry, seo_defaults.value),
})
</script>
